import './App.css';
import { useEffect, useState } from 'react';
import CV from './Downloadables/CV_Erwan_Thomy.pdf';
import userLogo from './images/user.jpg';
import work1 from './images/work-1.png';
import work2 from './images/work-2.png';
import work3 from './images/work-3.png';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import './i18n.js'

function App() {
  axios.defaults.withCredentials = true;
  useEffect(() => {
    AOS.init();
  }, [])

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState("");
  const [visibleProjects, setVisibleProjects] = useState(3);
  const [language, setLanguage] = useState("french");
  const [languages, setLanguages] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // Fetching data or setting data to make sure it's populated
    setLanguages([
      { value: 'fr', label: 'Français' },
      { value: 'en', label: 'English' },
    ]);
  }, []);

  const projects = [
    { id: 1, imgSrc: work1, title: t("projectTitle1"), description: t("projectDescription1"), link: "https://todolist.erwanthomy.fr" },
    { id: 2, imgSrc: work2, title: t("projectTitle2"), description: t("projectDescription2"), link: "#" },
    { id: 3, imgSrc: work3, title: t("projectTitle3"), description: t("projectDescription3"), link: "#" },
    { id: 4, imgSrc: work1, title: t("projectTitle4"), description: t("projectDescription4"), link: "#" },
    { id: 5, imgSrc: work2, title: t("projectTitle5"), description: t("projectDescription5"), link: "#" },
    { id: 6, imgSrc: work3, title: t("projectTitle6"), description: t("projectDescription6"), link: "#" },
    // Add more projects as needed
  ];

  const displayedProjects = projects.slice(0, visibleProjects);

  const sendEmail = () => {
    axios.defaults.withCredentials = true;
    if (checkCredentials) {
      axios.post('https://api.erwanthomy.fr/send-email', {
        name: name,
        email: email,
        message: message
      }).then((response) => {
        if (response.status === 200) {
          // SEND FEEDBACK HERE
          setSubmitted(true);
          setTimeout(() => {
            //document.getElementById('contact-me-form').reset();
            setName("");
            setEmail("");
            setMessage("")
            setSubmitted(false);
          }, 5000);
        }
      }).catch((error) => {
        console.log('Error:', error.message);
      });
    }
  }

  // Function to change the language
  const changeLanguage = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
  }


  const checkCredentials = () => {
    if (name === "" || email === "" || message === "") {
      return false;
    }
    else {
      return true;
    }
  }

  const TypeWriter = ({ contentList = [], speed = 100, delay = 1000 }) => {
    const [displayedContent, setDisplayedContent] = useState("");
    const [subIndex, setSubIndex] = useState(0); // Tracks the index of the current character
    const [isDeleting, setIsDeleting] = useState(false); // Whether we are deleting the text
    const [loopNum, setLoopNum] = useState(0); // Tracks which phrase we are on
    const [isPaused, setIsPaused] = useState(false); // For delaying after writing/deleting

    useEffect(() => {
      // Create the interval for updating the displayed content
      const timeout = setTimeout(() => {
        if (!isPaused) {
          const currentContent = contentList[loopNum % contentList.length];

          // Handle the typing effect
          if (!isDeleting) {
            setDisplayedContent(currentContent.slice(0, subIndex + 1));
            setSubIndex((prev) => prev + 1);

            // Start deleting when the entire content has been typed out
            if (subIndex === currentContent.length) {
              setIsPaused(true);
              setTimeout(() => {
                setIsDeleting(true);
                setIsPaused(false);
              }, delay); // Pause before deleting
            }
          }

          // Handle the deleting effect
          if (isDeleting) {
            setDisplayedContent(currentContent.slice(0, subIndex - 1));
            setSubIndex((prev) => prev - 1);

            // Switch to the next content after deleting
            if (subIndex === 0) {
              setDisplayedContent(''); // Set an empty string to avoid the full sentence flash
              setIsDeleting(false);
              setLoopNum((prev) => prev + 1);
              setIsPaused(true);
              setTimeout(() => setIsPaused(false), delay); // Pause before typing next sentence
            }
          }
        }
      }, speed);

      // Cleanup timeout on unmount
      return () => clearTimeout(timeout);
    }, [subIndex, isDeleting, isPaused, contentList, speed, delay, loopNum]);

    return <pre className="type-writer">{displayedContent}</pre>;
  };


  const sample_content = [t("typewriter1"),
  t("typewriter2"),
  t("typewriter3")];

  return (
    <div className="App">
      <div id="header">
        <div className="container">
          <nav>
            <p className='logo'>&lt;/<span>Erwan Thomy</span>&gt;</p>
            <ul id='sidemenu'>
              <li><a href="#header" onClick={() => closeMenu()} >{t("homeButton")}</a></li>
              <li><a href="#about" onClick={() => closeMenu()}>{t("aboutButton")}</a></li>
              <li><a href="#services" onClick={() => closeMenu()}>{t("servicesButton")}</a></li>
              <li><a href="#portfolio" onClick={() => closeMenu()}>{t("portfolioButton")}</a></li>
              <li><a href="#contact" onClick={() => closeMenu()}>{t("contactButton")}</a></li>
              <li>
                <div className="language-selector">
                  <label htmlFor="language"><i className="fa-light fa-globe" /></label>
                  <select className="styled-dropdown" id="language" onChange={changeLanguage}>
                    {languages.map(lng => {
                      return (
                        <option key={lng.value} value={lng.value}>{lng.label}</option>
                      )
                    })}
                  </select>
                </div>
              </li>
              <i className="fas fa-solid fa-xmark" onClick={() => closeMenu()}></i>
            </ul>
            <i className="fas fa-solid fa-bars" onClick={() => openMenu()}></i>
          </nav>
          <div className="hero">
            <div className="header-text">
              <p>{t("studentIntro")}</p>
              <h1>{t("beforeTypewriter")} <span>Erwan Thomy</span><br />
                <TypeWriter contentList={sample_content} speed={50} delay={2000} /></h1>
            </div>
            <div className="scroll-down">
              <p><a href="#about">{t("scrolldown")} <i className="fa-solid fa-arrow-down-long"></i></a></p>
            </div>
          </div>
        </div>
      </div>
      {/*------------------------------------------------ */}
      {/*--------------------- ABOUT -------------------- */}
      {/*------------------------------------------------ */}
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="about-col-1" data-aos="fade-right" data-aos-duration="1000">
              <img src={userLogo} alt="user logo" />
            </div>
            <div className="about-col-2">
              <h1 className='sub-title' data-aos="fade-down" data-aos-duration="500">{t("aboutHeader")}</h1>
              <div className="aos-container" data-aos="fade-up" data-aos-duration="3000">
                <p>{t("aboutParagraph")}</p>
                <div className="tab-titles">
                  <p className='tab-links active-link' onClick={(event) => opentab(event, 'skills')}>{t("tabSkills")}</p>
                  <p className='tab-links' onClick={(event) => opentab(event, 'experience')}>{t("tabExperience")}</p>
                  <p className='tab-links' onClick={(event) => opentab(event, 'education')}>{t("tabEducation")}</p>
                </div>
                <div className="tab-contents active-tab" id='skills'>
                  <ul>
                    <li><span>{t("tabSkills1")}</span><br />HTML, CSS, Javascript</li>
                    <li><span>{t("tabSkills2")}</span><br />Python, C#</li>
                    <li><span>{t("tabSkills3")}</span><br />MySQL</li>
                  </ul>
                </div>
                <div className="tab-contents" id='experience'>
                  <ul>
                    <li><span>2021-2024</span><br />{t("tabExperience1")}</li>
                  </ul>
                </div>
                <div className="tab-contents" id='education'>
                  <ul>
                    <li><span>{t("tabEducation1")}</span><br />{t("tabEducation2")}</li>
                    <li><span>{t("tabEducation3")}</span><br />{t("tabEducation4")}</li>
                    <li><span>{t("tabEducation5")}</span><br />{t("tabEducation6")}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*------------------------------------------------ */}
      {/*-------------------- Services ------------------- */}
      {/*------------------------------------------------ */}
      <div id="services">
        <div className="container">
          <h1 className="sub-title" data-aos="fade-right" data-aos-duration="500">{t("servicesHeader")}</h1>
          <div className="services-list">
            <div>
              <i className="fa-solid fa-code"></i>
              <h2>{t("servicesTitle1")}</h2>
              <p>{t("servicesDescription1")}</p>
            </div>
            <div>
              <i className="fa-solid fa-desktop"></i>
              <h2>{t("servicesTitle2")}</h2>
              <p>{t("servicesDescription2")}</p>
            </div>
            <div>
              <i className="fa-solid fa-mobile-screen"></i>
              <h2>{t("servicesTitle3")}</h2>
              <p>{t("servicesDescription3")}</p>
            </div>
          </div>
        </div>
      </div>
      {/*------------------------------------------------ */}
      {/*------------------- Portfolio ------------------ */}
      {/*------------------------------------------------ */}
      <div id="portfolio">
        <div className="container">
          <h1 className="sub-title" data-aos="fade-right" data-aos-duration="500">Mes Travaux</h1>
          <div className="work-list">
            {displayedProjects.map((project, index) => (
              <div key={project.id} className="work" data-aos="fade-up" data-aos-duration={`${300 + index * 500}`}>
                <img src={project.imgSrc} alt={project.title} />
                <div className="layer">
                  <h3>{project.title}</h3>
                  <p>{project.description}</p>
                  <a href={project.link}><i className="fa-solid fa-arrow-up-right-from-square"></i></a>
                </div>
              </div>
            ))}
          </div>
          {visibleProjects < projects.length && (<div onClick={() => setVisibleProjects(visibleProjects + 3)} className="btn" data-aos="fade-up">{t("seeMore")}</div>)}
          {visibleProjects >= projects.length && (<div onClick={() => setVisibleProjects(visibleProjects - 3)} className="btn" data-aos="fade-up">{t("seeLess")}</div>)}
        </div>
      </div>
      {/*------------------------------------------------ */}
      {/*------------------- Contact ------------------ */}
      {/*------------------------------------------------ */}
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="contact-left">
              <h1 className='sub-title'>{t("contactHeader")}</h1>
              <p><i className="fa-solid fa-paper-plane"></i> contact@erwanthomy.fr</p>
              <p><i className="fa-solid fa-square-phone"></i> +33 6 52 90 40 49</p>
              <div className="social-icons">
                <a href="https://www.linkedin.com/in/erwan-thomy/"><i className="fa-brands fa-linkedin"></i></a>
                <a href="https://www.france-ioi.org/user/perso.php?sLogin=exylus"><i className="fa-brands fa-python"></i></a>
                <a href="https://www.instagram.com/erwan.thm/"><i className="fa-brands fa-instagram"></i></a>
                <a href="https://github.com/Exylus"><i className="fa-brands fa-github"></i></a>
              </div>
              <a href={CV} className='btn btn2'>{t("downloadButton")}</a>
            </div>
            <div className="contact-right">
              <form id='contact-me-form'>
                <input type="text" name='Name' value={name} placeholder={t("namePlaceholder")} onChange={(e) => setName(e.target.value)} required />
                <input type="email" name='Email' value={email} placeholder={t("emailPlaceholder")} onChange={(e) => setEmail(e.target.value)} required />
                <textarea name="Message" rows='6' value={message} placeholder={t("messagePlaceholder")} onChange={(e) => setMessage(e.target.value)} required></textarea>
                <div className="submit-validation">
                  <button type='button' className='btn btn2' onClick={sendEmail} style={{ backgroundColor: submitted ? '#4CAF50' : '#64d9fa' }}>{submitted ? <i className="fa-solid fa-check"></i> : t("sendMessage")}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer className="copyright">
        <div className="up" id="up" onClick={goBackUp}><i className="fa-solid fa-chevron-up"></i></div>
        <p>{t("footer1")}<i className="fa-solid fa-heart"></i>{t("footer2")}</p>
      </footer>
    </div >
  );
}

export default App;

function opentab(event, tabname) {
  const tablinks = document.getElementsByClassName("tab-links");
  const tabcontents = document.getElementsByClassName("tab-contents");

  for (let tablink of tablinks) {
    tablink.classList.remove("active-link");
  }
  for (let tabcontent of tabcontents) {
    tabcontent.classList.remove("active-tab");
  }
  event.currentTarget.classList.add("active-link");
  document.getElementById(tabname).classList.add("active-tab")
}

function openMenu() {
  const sideMenu = document.getElementById("sidemenu")
  sideMenu.style.right = "0";
}

function closeMenu() {
  const sideMenu = document.getElementById("sidemenu")
  sideMenu.style.right = "-200px"
}

function goBackUp() {
  const element = document.getElementById('header');
  element.scrollIntoView({ behavior: "smooth" });
}